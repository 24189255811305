<template>
    <div class="my-[5rem]">
        <h1 class="text-center font-bold text-4xl text-primary">ទំនាក់ទំនងតាមប្រអប់សារ</h1>
        <div class="w-4/12 mx-auto my-[5rem]">
            <form action="">
                <div class="space-y-4">
                    <div class="flex flex-col space-y-2">
                        <label class="text-xl" for="">ឈ្មោះ<span class="text-red-500">*</span></label>
                        <input required
                            class="p-3 rounded-md w-full hover:border-primary transition-all duration-150 border-2 hover:border-2 outline-none border-gray-300 pl-4 text-lg"
                            type="text" placeholder="ឈ្មោះ">
                    </div>
                    <div class="flex flex-col space-y-2">
                        <label class="text-xl" for="">ឈ្មោះក្រុមហ៊ុន<span class="text-red-500">*</span></label>
                        <input required
                            class="p-3 rounded-md w-full hover:border-primary transition-all duration-150 border-2 hover:border-2 outline-none border-gray-300 pl-4 text-lg"
                            type="text" placeholder="ឈ្មោះក្រុមហ៊ុន">
                    </div>

                    <div class="flex flex-col space-y-2">
                        <label class="text-xl" for="">លេខទូរស័ព្ទ<span class="text-red-500">*</span></label>
                        <input required
                            class="p-3 rounded-md w-full hover:border-primary transition-all duration-150 border-2 hover:border-2 outline-none border-gray-300 pl-4 text-lg"
                            type="text" placeholder="លេខទូរស័ព្ទ">
                    </div>
                    <div class="flex flex-col space-y-2">
                        <label class="text-xl" for="">លេខតេក្រាម<span class="text-red-500">*</span></label>
                        <input required
                            class="p-3 rounded-md w-full hover:border-primary transition-all duration-150 border-2 hover:border-2 outline-none border-gray-300 pl-4 text-lg"
                            type="text" placeholder="លេខតេក្រាម">
                    </div>

                    <div class="flex flex-col space-y-2">
                        <label class="text-xl" for="">អាស័យដ្ឋាន<span class="text-red-500">*</span></label>
                        <input required
                            class="p-3 rounded-md w-full hover:border-primary transition-all duration-150 border-2 hover:border-2 outline-none border-gray-300 pl-4 text-lg"
                            type="text" placeholder="អាស័យដ្ឋាន">
                    </div>
                    <div class="flex flex-col space-y-2">
                        <label class="text-xl" for="">ជ្រើសរើសសេវាកម្ម<span class="text-red-500">*</span></label>
                        <select required
                            class="p-3 rounded-md w-full hover:border-primary transition-all duration-150 border-2 hover:border-2 outline-none border-gray-300 pl-4 text-lg"
                            type="text">

                            <option value="Business Management System">Business Management System</option>
                            <option value="Website Design">Website Design</option>
                            <option value="System Development">System Development</option>
                        </select>
                    </div>

                    <div class="flex flex-col space-y-2">
                        <label class="text-xl" for="">គោលបំណង<span class="text-red-500">*</span></label>
                        <textarea rows="5" required
                            class=" p-3 rounded-md w-full hover:border-primary transition-all duration-150 border-2 hover:border-2 outline-none border-gray-300 pl-4 text-lg"
                            placeholder="គោលបំណង">
                        </textarea>
                    </div>

                    <div>
                        <button type="submit" class="p-4 px-[6rem] bg-primary rounded-md text-white text-xl">បញ្ជូន</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style></style>