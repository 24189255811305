<template>
  <div class="w-9/12 mx-auto">
    <h1 class="text-center text-4xl my-10 text-primary font-bold">ទាក់ទងមកពួកយើង</h1>
    <div class="text-center">
      <h3 class="text-lg font-bold text-indigo-600/50">ជួយឱ្យអ្នកគ្រប់គ្រងអាជីវកម្មស្រួល</h3>
      <p class="text-lg font-bold text-indigo-600/50">និងរីកចម្រើនលឿន
      </p>
    </div>
    <div class="w-11/12 my-4 mx-auto">
      <p class="text-xl text-gray-800">ប្រឹក្សាយោបល់ ផ្ដល់ដំណោះស្រាយផ្នែកបច្ចេកវិទ្យា ដើម្បីឱ្យអតិថិជនទទួលបានគេហទំព័រ
        ឬប្រព័ន្ធគ្រប់គ្រងទិន្នន័យសម្រាប់អាជីវកម្មដែលល្អបំផុត បំពេញតម្រូវការរបស់លោកអ្នកបានច្រើនបំផុត
        និងចំណាយប្រាក់តិចបំផុត។</p>
    </div>


    <div class=" mx-auto">
      <div class="text-center text-white text-xl">
        <p>យើងជឿជាក់ថា កក្តាដ៏សំខាន់មួយ ជួយធ្វើឱ្យអាជីវកម្ម និងប្រទេសជាតិរីកចម្រើនលឿន
          គឺកក្តាជឿនលឿននៃបច្ចេកវិទ្យា
        </p>
        <p>ម៉ាស្ទ័រអាយធីស៊ីស្លីម នឹងក្លាយជាក្រុមហ៊ុនបច្ចេកវិទ្យា ឈានមុខគេ បង្កើតមោតនភាពជូនប្រជាជនកម្ពុជា។</p>
      </div>



      <div class=" mx-auto">
        <div class="grid grid-cols-2 flex-wrap">
          <div class="text-black">
            <div class="space-y-4">
              <div class="flex space-x-2 items-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                  stroke="currentColor" class="size-12 text-primary">
                  <path stroke-linecap="round" stroke-linejoin="round"
                    d="M12 21a9.004 9.004 0 0 0 8.716-6.747M12 21a9.004 9.004 0 0 1-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 0 1 7.843 4.582M12 3a8.997 8.997 0 0 0-7.843 4.582m15.686 0A11.953 11.953 0 0 1 12 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0 1 21 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0 1 12 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 0 1 3 12c0-1.605.42-3.113 1.157-4.418" />
                </svg>
                <div>
                  <p class="text-xl font-bold font-lg">អាស័យដ្ឋាន:</p>
                  <p class="text-blue-500 text-lg">ភូមិរំចេក៤ សង្កាត់រតនៈ ក្រុងបាត់ដំបង ខេត្ត<br />បាត់ដំបង</p>
                </div>
              </div>
              <div class="flex space-x-2 items-center">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                  class="lucide lucide-globe-lock size-10 text-primary">
                  <path d="M15.686 15A14.5 14.5 0 0 1 12 22a14.5 14.5 0 0 1 0-20 10 10 0 1 0 9.542 13" />
                  <path d="M2 12h8.5" />
                  <path d="M20 6V4a2 2 0 1 0-4 0v2" />
                  <rect width="8" height="5" x="14" y="6" rx="1" />
                </svg>
                <div>
                  <p class="text-xl font-bold font-lg">គេហទំព័រ:</p>
                  <a href="https://masteritsystem.com/" class="text-blue-500 text-lg">www.masteritsystems.com</a>
                </div>
              </div>
              <div class="flex space-x-2 items-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                  stroke="currentColor" class="size-12 text-primary">
                  <path stroke-linecap="round" stroke-linejoin="round"
                    d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z" />
                </svg>

                <div>
                  <p class="text-xl font-bold font-lg">ទំនាក់ទំនង:</p>
                  <p class="text-blue-500 text-lg">095 956 656 / 096 50 21 168</p>
                </div>
              </div>

              <div class="flex space-x-2 items-center">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                  class="lucide lucide-mail size-10 text-primary">
                  <rect width="20" height="16" x="2" y="4" rx="2" />
                  <path d="m22 7-8.97 5.7a1.94 1.94 0 0 1-2.06 0L2 7" />
                </svg>

                <div>
                  <p class="text-xl font-bold font-lg">អ៊ីមែល:</p>
                  <a href="master.it.system.team@gmail.com"
                    class="text-blue-500 text-lg">master.it.system.team@gmail.com</a>
                </div>
              </div>

              <div class="flex space-x-2 items-center">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                  class="lucide lucide-users size-10 text-primary">
                  <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
                  <circle cx="9" cy="7" r="4" />
                  <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
                  <path d="M16 3.13a4 4 0 0 1 0 7.75" />
                </svg>

                <div>
                  <p class="text-xl font-bold font-lg">ទីប្រឹក្សាតាមតេឡេក្រាម:</p>
                  <p class="text-blue-500 text-lg">095 956 656</p>
                </div>
              </div>
            </div>


          </div>

          <div class="text-black -mt-10">
            <div class="">
              <div class="space-y-4 ml-[5rem] mt-[1rem]">
                <div class="flex  space-x-2 items-center ">
                  <img width="100px" src="https://masteritsystem.com/img/image%205.9e29af1f.png" alt="">
                  <p class="text-lg font-bold ">ម៉ាស្ទ័រអាយធី ស៊ីស្ទីម</p>
                </div>

                <div class="space-y-3">
                  <div class="flex space-x-2 items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                      stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                      class="lucide lucide-facebook bg-blue-500 p-2 rounded-full text-white size-10">
                      <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z" />
                    </svg>
                    <div>
                      <p class="text-lg ml-2 font-lg">Master-IT System</p>
                    </div>
                  </div>
                  <div class="flex space-x-2 items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                      stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                      class="lucide lucide-youtube bg-red-500 p-2 rounded-full size-10 text-white">
                      <path
                        d="M2.5 17a24.12 24.12 0 0 1 0-10 2 2 0 0 1 1.4-1.4 49.56 49.56 0 0 1 16.2 0A2 2 0 0 1 21.5 7a24.12 24.12 0 0 1 0 10 2 2 0 0 1-1.4 1.4 49.55 49.55 0 0 1-16.2 0A2 2 0 0 1 2.5 17" />
                      <path d="m10 15 5-3-5-3z" />
                    </svg>
                    <div>
                      <p class="text-xl ml-2 font-lg">Master-IT System</p>
                    </div>
                  </div>
                  <div class="flex space-x-2 items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                      stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                      class="lucide lucide-send bg-blue-600 p-2 rounded-full size-10 text-white">
                      <path d="m22 2-7 20-4-9-9-4Z" />
                      <path d="M22 2 11 13" />
                    </svg>
                    <div>
                      <p class="text-lg ml-2 font-lg">Master-IT System</p>
                    </div>
                  </div>
                  <div class="flex items-center space-x-3">
                    <p class="font-bold text-lg border-r-2 border-primary p-3">ស្កេនទីនេះ</p>
               
                    <img src="https://masteritsystem.com/img/qr.d791b8b1.png" class="flex " width="100px" alt="">
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
  <FormInputComponentVue/>
  <FooterComponentVue/>
</template>

<script>
import FooterComponentVue from '@/components/FooterComponent.vue'
import FormInputComponentVue from '@/components/FormInputComponent.vue'
export default {
  components:{
    FooterComponentVue,
    FormInputComponentVue
  }
}
</script>

<style></style>