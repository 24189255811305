<template>
    <header class=" shadow-md bg-white sticky top-0 z-10">
        <nav
            class="w-[75%] lg:w-[90%] xl:w-[75%] lg:mx-auto xl:mx-auto mx-auto flex items-center justify-between py-3 md:py-[3rem]">
            <div class="flex  space-x-2 items-center justify-center w-full xl:w-full  relative ">
                <div class="flex justify-center  xl:w-0">
                    <div class="absolute top-2 -left-10  md:-left-[5rem] md:top-8  sm:flex md:flex xl:hidden">
                        <svg v-if="!isOpen" @click="isOpen = !isOpen" xmlns="http://www.w3.org/2000/svg" fill="none"
                            viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                            class="size-7 md:size-8 font-bold cursor-pointer">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                        </svg>
                        <svg v-else @click="isOpen = !isOpen" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                            stroke="currentColor" class="size-7 cursor-pointer">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
                        </svg>

                    </div>
                </div>
                <div class="xl:w-full">
                    <div class="flex items-center space-x-3 bg-blue-5">
                        <img width="40px" class="md:w-[90px]"
                            src="https://masteritsystem.com/img/image%205.9e29af1f.png" alt="">
                        <div>
                            <p class="text-lg md:text-3xl  text-primary font-bold">ម៉ាស្ទ័រអាយធី ស៊ីស្ទីម</p>
                        </div>
                    </div>
                </div>

            </div>

            <div class="xl:w-full mx-auto">
                <ul class=" hidden space-x-10  xl:flex w-full justify-end lg:space-x-7 xl:-ml-[4rem] ">
                    <li v-for="nav in nav_item" :key="nav">
                        <router-link :to="{ path: nav.link }" class="text-2xl font-bold text-primary">
                            {{ nav.names }}
                        </router-link>
                    </li>
                </ul>
            </div>
        </nav>
    </header>
    <!-- responsive -->
 
        <aside v-show="isOpen" class="flex bg-black/30 left-0 w-full h-full fixed lg:hidden z-10">
            <Transition name="slide-fade">
            <nav v-if="isOpen"  v-motion-slide-visible-left  class="bg-white w-[50%] h-full fixed z-10 transform ease-out p-3">
                <ul class="space-y-5 p-2">
                    <li v-for="nav in nav_item" :key="nav">
                        <router-link @click="isOpen = !isOpen" :to="{ path: nav.link }" class="text-xl font-bold text-primary">
                            {{ nav.names }}
                        </router-link>
                    </li>
                </ul>
            </nav>
        </Transition>
        </aside>

</template>

<script>
import { ref } from 'vue';

export default {
    setup() {
        const nav_item = ref([
            {
                link: "/",
                names: "ទំព័រដើម"
            },
            {
                link: "/about",
                names: "អំពីពួកយើង"
            },
            {
                link: "/service",
                names: "សេវ៉ាកម្ម"
            },
            {
                link: "/contact",
                names: "ទំនាក់ទំនង"
            },
            {
                link: "/contact",
                names: "ចូលប្រើប្រាស់"
            }
        ])

        const isOpen = ref(false)

        return { nav_item, isOpen }
    }
}
</script>

<style scoped>

.slide-fade-enter-active {
  /* transition: all 0.2s ease-in-out ; */
  /* transition: all 0.8s cubic-bezier(3, 2, 2.5, 4); */
  /* transition: all 0.4s cubic-bezier(1, 0.5, 0.8, 1); */


  margin-left: 50px;
  /* animation: bounce-in 0.4s; */
  /* transform: scaleZ(); */
  

}

.slide-fade-leave-active {
  transition: all 0.1s;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(-20px);
  opacity: 0;
}


</style>