<template>
    <div class="bg-primary ">
        <div class="">
            <div class="grid grid-cols-1 md:grid-cols-2 gap-3 ">
                <div  class="md:flex items-center justify-center py-8 mt-3  hidden lg:ml-2">
                    <div class="text-white space-y-6 md:m-5 lg:p-2">
                        <h1 class="text-5xl font-bold lg:text-[67px]">ម៉ាស្ទ័រអាយធី ស៊ីស្ទីម</h1>
                        <h4 class="text-3xl lg:text-3xl text-yellow-400">ជួយឱ្យអ្នកគ្រប់គ្រងអាជីវកម្មងាយស្រួល និងរីកចម្រើន</h4>
                        <button class="bg-yellow-500 text-2xl hover:bg-yellow-700 transition-all duration-300 ease-in-out p-3 px-4 text-primary rounded-full">ទំនាក់មកកាន់ពួកយើង</button>
                    </div>
                </div>
                <div class="overflow-hidden">
                    <div
                        class=" w-[800px]  border-[15px] border-white  lg:w-[1300px]  rounded-full -mt-[8rem] ml-3 lg:-mt-[10rem] lg:-mb-[4rem]  lg:ml-9rem] ">
                        <img class="object-cover  rounded-full  h-[800px] lg:h-[1000px]"
                            src="https://masteritsystem.com/img/ImageCover.25f59950.jpg"
                            alt="nature image" />
                    </div>
                </div>
                <div  class="flex items-center justify-center py-8 mt-3 md:hidden">
                    <div class="text-white space-y-4 text-center m-2">
                        <h1 class="text-3xl font-bold">ម៉ាស្ទ័រអាយធី ស៊ីស្ទីម</h1>
                        <h4 class="text-xl text-yellow-400">ជួយឱ្យអ្នកគ្រប់គ្រងអាជីវកម្មងាយស្រួល និងរីកចម្រើន</h4>
                        <button class="bg-yellow-400 hover:bg-yellow-700 transition-all duration-300 ease-in-out p-3 px-4 text-primary rounded-full">ទំនាក់មកកាន់ពួកយើង</button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style></style>