<template>
    <div>
      <NavbarComponentVue/>
      <router-view/>
    </div>
</template>

<script>
import NavbarComponentVue from './components/NavbarComponent.vue'
export default {
  components: {
    NavbarComponentVue
  }
}
</script>

<style>

</style>