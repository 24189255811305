<template>
    <div class="bg-primary">
        <div class="w-[80%] mx-auto">
            <div class="text-center text-white text-xl py-8">
                <p>យើងជឿជាក់ថា កក្តាដ៏សំខាន់មួយ ជួយធ្វើឱ្យអាជីវកម្ម និងប្រទេសជាតិរីកចម្រើនលឿន
                    គឺកក្តាជឿនលឿននៃបច្ចេកវិទ្យា
                </p>
                <p>ម៉ាស្ទ័រអាយធីស៊ីស្លីម នឹងក្លាយជាក្រុមហ៊ុនបច្ចេកវិទ្យា ឈានមុខគេ បង្កើតមោតនភាពជូនប្រជាជនកម្ពុជា។</p>
            </div>
            <hr class="py-2">
            <h3 class="text-center text-2xl font-bold text-white">បណ្ដាញសង្គម</h3>
            <div class="w-full mt-7">
                <div class="flex justify-center">
                    <div class="flex justify-between  w-72 bg-indigo-700/30 p-3 rounded-full">
                        <div
                            class="bg-white w-12 h-12 rounded-full hover:bg-blue-500 hover:text-white transition-all duration-150 ease-in-out flex justify-center items-center text-primary cursor-pointer">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round" class="lucide lucide-send">
                                <path d="m22 2-7 20-4-9-9-4Z" />
                                <path d="M22 2 11 13" />
                            </svg>
                        </div>
                        <div
                            class="bg-white w-12 h-12 hover:bg-blue-700 hover:text-white transition-all duration-150 ease-in-out  rounded-full flex justify-center items-center text-primary cursor-pointer">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round" class="lucide lucide-facebook">
                                <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z" />
                            </svg>
                        </div>
                        <div
                            class="bg-white hover:bg-gradient-to-r hover:from-purple-500 hover:text-white hover:to-pink-500 w-12 h-12 rounded-full flex justify-center items-center text-primary cursor-pointer">

                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round" class="lucide lucide-message-circle">
                                <path d="M7.9 20A9 9 0 1 0 4 16.1L2 22Z" />
                            </svg>
                        </div>
                        <div
                            class="bg-white hover:bg-red-500 hover:text-white transition-all duration-150 ease-in-out  w-12 h-12 rounded-full flex justify-center items-center text-primary cursor-pointer">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round" class="lucide lucide-youtube">
                                <path
                                    d="M2.5 17a24.12 24.12 0 0 1 0-10 2 2 0 0 1 1.4-1.4 49.56 49.56 0 0 1 16.2 0A2 2 0 0 1 21.5 7a24.12 24.12 0 0 1 0 10 2 2 0 0 1-1.4 1.4 49.55 49.55 0 0 1-16.2 0A2 2 0 0 1 2.5 17" />
                                <path d="m10 15 5-3-5-3z" />
                            </svg>
                        </div>
                    </div>
                </div>
            </div>

            <div class="w-[90%] mx-auto mt-10 pb-5">
                <div class="flex justify-between flex-wrap">
                    <div class="text-white">
                        <div class="flex justify-center ">
                            <img width="150px" src="https://masteritsystem.com/img/image%205.9e29af1f.png" alt="">
                        </div>
                        <div class="text-center space-y-2 mt-4">
                            <h1 class="text-4xl font-bold">Master IT System</h1>
                            <p>យើងនឹងជួយអ្នកឱ្យគ្រប់គ្រងអាជីវកម្មរបស់អ្នកបានយ៉ាងងាយ</p>
                            <p>ស្រួល និងរីកចម្រើនយ៉ាងឆាប់រហ័ស</p>
                        </div>
                    </div>

                    <div class="text-white mt-4 -ml-9 md:ml-0">
                        <div class="space-y-1">
                            <h1 class="text-2xl text-center my-3 md:text-start">ទាក់ទងមកពួកយើង</h1>
                            <div class="space-y-4 p-4">
                                <p class="text-md"><b>អ៊ីមែល</b> : masterit.system@gmail.com</p>
                                <p class="text-md"><b>ទូរស័ព្ទ</b> : 095 956 656 / 096 50 21 168</p>
                                <p class="text-md"><b>អ៊ីមែល</b> : https://t.me/masteritsystems_saleconsultant</p>
                                <p class="text-md"><b>អាស័យដ្ឋាន</b> : ភូមិ រំចេក បួន សង្កាត់រតនៈ ក្រុង បាត់ដំបង
                                    ខេត្តបាត់ដំបង8</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="bg-purple-900/90 py-4">
        <div class="text-center space-y-2 text-white">
            <h3 class="text-xl text-gray-400">
                In Terms of Use | Privacy Policy
            </h3>
            <h3 class="text-xl text-gray-400">@2012-2024 Copy right | Master-IT System</h3>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style></style>