<template>
  <div class="w-9/12 mx-auto">
    <div class="my-10 ">
      <h1 class="text-3xl font-bold text-primary text-center">អំពីយើង</h1>
    </div>
    <div class="grid grid-cols-2 gap-5">
      <div>
        <img class="rounded-lg" src="https://masteritsystem.com/img/about.88729375.jpg" alt="">
      </div>
      <div class="">
        <div class="text-xl">
          តម្រូវការអនាគតឆ្ពោះទៅរកបច្ចេកវិទ្យាឌីជីថលទំនើប និង
          មើលឃើញពីការលំបាករបស់អាជីវកម្មជាច្រើននៅក្នុងការគ្រប់គ្រងប្រតិបត្តការប្រចាំថ្ងៃ ដែលមានតែភាពមិនច្បាស់លាស់ មិន
          រលូន
          មានតែការបាត់បង់ និងមានភាពខ្ជះខ្ជាយ ទាំងនេះជាឧបសគ្គដ៏ធំ រារាំងអាជីវកម្មជាច្រើន មិនអាចរីកចម្រើនបានលឿន
          ម៉ាស្ទ័រអាយធី ស៊ីស្ទីម ត្រូវបានបង្កើតឡើងដើម្បីឆ្លើយតបលើតម្រូវការ និងដោះស្រាយរាល់បញ្ហា ការលំបាកទាំងអស់នេះរបស់
          អាជីវកម្ម ហើយជួយឱ្យអាជីវកម្មអាចគ្រប់គ្រងបានកាន់តែស្រួល កាន់តែច្បាស់ និងរីកចម្រើនកាន់តែលឿន តាមរយៈការបន្ត
          អភិវឌ្ឍន៍ឥតឈប់ឈរ នូវប្រព័ន្ធបច្ចេកវិទ្យាដែលទំនើប ទាន់សម័យ ស្តង់ដារ សាមញ្ញ ងាយស្រួលប្រើ។
        </div>
      </div>
    </div>

    <div class="my-10 grid grid-cols-1 md:grid-cols-2 gap-10">
      <div v-for="fs in filesetTitle" :key="fs">
        <div class="space-x-8">
          <fieldset class="border-2  border-primary p-6 h-full  rounded-lg">
            <legend class="text-2xl text-primary font-bold">{{ fs.caption }}</legend>
            <div>
              <p class="text-lg ml-5 my-8">{{ fs.description }}</p>
              <div class="text-lg ml-5 my-8 w-full">
                <p>{{ fs.desc1 }}</p>
                <p>{{ fs.desc2 }}</p>
                <p>{{ fs.desc3 }}</p>
              </div>
            </div>
          </fieldset>

        </div>
      </div>
    </div>

    <main class="space-y-10 my-10">
      <div class="flex justify-center w-[80%] mx-auto">
        <div class="border-2 rounded-md p-3 w-[80%]">
          <h2 class="text-2xl font-bold ml-2 text-primary">ហេតុអ្វីជ្រើសរើស ម៉ាស្ទ័រអាយធី ស៊ីស្ទឹម?</h2>
          <div class="flex">
            <div class=" my-5 space-y-1 ml-2">
              <p class="text-lg">1. មានបទពិសោធន៍ក្នុងវិស័យបច្ចេកវិទ្យា និងអាជីវកម្មចាប់តាំងពីឆ្នាំ២០១២</p>
              <p class="text-lg">2. នាំមកនូវប្រព័ន្ធបច្ចេកវិទ្យា មានគុណភាព និងមានស្តង់ដារ</p>

              <p class="text-lg">3. ផ្ដល់ទំនុកចិត្តកំរិតខ្ពស់បំផុត (ធានា៥យ៉ាង)</p>

              <p class="text-lg">4. និងការរក្សាភាពជាដៃគូរដ៏ល្អ និងយូរអង្វែង មានក្រុមការងារតាមជំនាញ</p>

              <p class="text-lg">5. ជួយអតិថិជនបានគ្រប់កាលៈទេសៈ បំពេញតម្រូវការអតិថិជនបានច្រើនបំផុត</p>

              <p class="text-lg">6. និងជួយឱ្យចំណេញប្រាក់តិចបំផុត ជួយឱ្យគ្រប់អាជីវកម្មគ្រប់គ្រងកាន់តែស្រួល កាន់តែច្បាស់
              </p>
              <p class="text-lg">7. រីកចម្រើនកាន់តែលឿន ជួយបង្កើតមោទនភាពជូនប្រជាជនកម្ពុជា</p>
            </div>
            <div class="-ml-3 ">
              <img class="w-[300px] h-[200px] object-conatain" src="https://masteritsystem.com/img/qmark.c8c07816.png"
                alt="">
            </div>
          </div>
        </div>
      </div>

  
      <div class="flex justify-center w-[90%] mx-auto">
        <div class="border-2 rounded-md p-3 w-[71%]">
          <h2 class="text-2xl font-bold ml-2 text-primary">តើម៉ាស្ទ័រអាយធី ស៊ីស្ទឹម ធានាជូនអ្វីខ្លះ?</h2>
          <div class="flex">
            <div class=" my-5 space-y-1 ml-2">
              <p class="text-lg">1. ធានាលើការបង្កើត និងគ្រប់គ្រងរាល់បញ្ហា Error
                ទាំងអស់នៅក្នុងប្រព័ន្ធគ្រប់គ្រងទិន្នន័យបើមាន។</p>
              <p class="text-lg">2. ធានាលើការបាត់បង់ទិន្នន័យ ក្នុងទំនួលខុសត្រូវរបស់ក្រុមហ៊ុន។</p>

              <p class="text-lg">3. ធានារក្សាការសម្ងាត់បំផុតនៃទិន្នន័យទាំងអស់ក្នុងប្រព័ន្ធគ្រប់គ្រងទិន្នន័យ។</p>

              <p class="text-lg">4. ធានាលើការចម្លងទុក (Backup) ទិន្នន័យទាំងអស់ក្នុងប្រព័ន្ធគ្រប់គ្រងទិន្នន័យ។</p>

              <p class="text-lg">5. ធានាបើអតិថិជនមិនពេញចិត្ត មិនគិតប្រាក់។</p>
            </div>
            <div class=" ml-10 ">
              <img class="w-[200px] h-[200px] object-conatain"
                src="https://masteritsystem.com/img/warramty.153dc483.png" alt="">
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
  <FooterComponentVue />
</template>

<script>
import { ref } from 'vue'
import FooterComponentVue from '@/components/FooterComponent.vue'
export default {
  components: {
    FooterComponentVue
  },
  setup() {
    const filesetTitle = ref([
      {
        id: 1,
        caption: "ទស្សនៈវិស័យ",
        description: "យើងជឿជាក់ថា កត្តាដ៏សំខាន់មួយជួយធ្វើឱ្យអាជឺវកម្ម និងប្រទេសជាតិរីកចម្រើនលឿន គឺកត្តាជឿនលឿននៃបច្ចេកវិទ្យា ម៉ាស្ទ័រអាយធីស៊ីស្ទឹម នឹងក្លាយជាក្រុមហ៊ុនបច្ចេកវិទ្យាឈានមុខគេ បង្កើតមោតទនភាពជូនប្រជាជនកម្ពុជា។"
      },
      {
        id: 2,
        caption: "បេសកម្ម",
        description: "នាំមកនូវប្រព័ន្ធបច្ចេកវិទ្យាថ្មី ឈានមុខគេសម្រាប់គ្រប់អាជីវកម្ម និងប្រទេសកម្ពុជា តាមរយៈការរួមគ្នាបន្តការស្រាវជ្រាវ បន្តការអភិវឌ្ឍន៍ឥតឈប់ឈរ បន្តការផ្ដល់ជូននូវបទពិសោធន៍ប្រើប្រាស់ និងសេវាកម្មដ៏ល្អជាទីទុកចិត្ត ពេញចិត្តជូនអតិថិជនគ្រប់កាលៈទេសៈ។"
      },
      {
        id: 3,
        caption: "គុណតម្លៃ",
        desc1: "ប្រព័ន្ធស្ដង់ដារ (ទំនើប ច្បាស់លាស់ សាមញ្ញ ងាយស្រួលប្រើ)",
        desc2: "សេវាកម្មលឿនរហ័សបំផុត ជាទីពេញចិត្ត និងទំនុកចិត្ត មានវិជ្ជាជីវៈ",
        desc3: "ស្មោះត្រង់ ទទួលខុសត្រូវ ភាពជាដៃគូរដ៏ល្អ និងយូរអង្វែង។"

      },
      {
        id: 4,
        caption: "យើងផ្ដោតលើ",
        description: "ការស្រាវជ្រាវ និងអភិវឌ្ឍន៍ឥតឈប់ឈរ ការច្នៃប្រឌិតបង្កើតថ្មី ការផ្គត់ផ្គង់ប្រព័ន្ធមានស្ដង់ដារគុណភាពខ្ពស់ ការបង្កើតឱ្យមានការទទួលស្គាល់ (ទទួលបានពានរង្វាន់) ការទំនាក់ទំនងយ៉ាងជិតស្និតជាមួយអតិថិជន"
      }
    ])




    return { filesetTitle }
  }
}

</script>
