<template>
  <div>
    <BannerComponentVue />
    <div class="text-center">
      <p class="text-2xl font-bold my-5">ផលិតផលលេចធ្លោ</p>
    </div>
    <div class="mx-auto md:w-5/12 mb-[6rem]">
      <div class="flex gap-6 justify-center">
        <div v-for="item in image_url" :key="item" class="relative">
          <div>
            <img class="rounded-md object-cover" style="height: 400px;width:400px" :src="item.url" alt="">
          </div>
          <div
            class=" w-full h-full  transition-all duration-300 ease-in-out   flex  justify-center absolute -bottom-72 p-3">
            <div
              class="h-40 shadow-md  hover:-translate-y-4 transform  w-full backdrop-blur-md bg-white/30  hover:bg-white/60 transition-all duration-300 ease-in-out  rounded-md p-3 ">
              <h2 class="text-2xl ">{{ item.title }}</h2>
              <p class="line-clamp-3  text-lg">{{ item.description }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="flex justify-center my-2">
      <div class="bg-primary w-12 h-7 rounded-full"></div>
    </div>
    <div class="bg-gray-200">
      <p class="text-center text-2xl font-bold pt-10 text-primary ">ដៃគូរបស់យើង</p>

      <div class="w-7/12 mx-auto py-[12rem]">
        <div class="flex flex-wrap justify-between relative">
          <div v-for="l in logo" :key="l">
            <div>
              <img :src="l.url" width="150px" alt="">
            </div>
            <div
              class="w-[150px] h-[152px] hover:bg-transparent transition-all duration-150 ease-in-out  bg-gray-200/80 absolute top-0">

            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="my-[8rem]">
      <div class="w-7/12 mx-auto">
        <div class="grid grid-cols-1 md:grid-cols-2">
          <div>
            <img width="400px" src="https://masteritsystem.com/img/faq.30ab428f.png" alt="">
          </div>
          <div class="lg:ml-[10rem] sm:ml-0 mt-5 space-y-3">
            <div class="flex justify-center">
              <div @click="isShow = !isShow"
                class="cursor-pointer bg-white border w-full ml-3  p-3 rounded-md relative">
                <div class="font-bold text-lg flex justify-between w-full">
                  <div>What is your name ?</div>
                  <span>
                    <svg v-if="!isShow" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                      stroke-width="1.5" stroke="currentColor" class="size-6">
                      <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                    </svg>
                    <svg v-else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                      stroke="currentColor" class="size-6">
                      <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 15.75 7.5-7.5 7.5 7.5" />
                    </svg>
                  </span>
                </div>
                <div v-if="isShow">
                  It is a long established fact that a reader will be distracted by the readable content of a page when
                  looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal
                  distribution of letters, as opposed to using 'Content here, content here', making it look like
                  readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their
                  default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy.
                  Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected
                  humour and the like).
                </div>
              </div>
            </div>
            <div class=" flex justify-center">
              <div @click="isShow1 = !isShow1"
                class="cursor-pointer bg-white border w-full ml-3  p-3 rounded-md relative">
                <div class="font-bold text-lg flex justify-between w-full">
                  <div>Where do you live ?</div>
                  <span>
                    <svg v-if="!isShow1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                      stroke-width="1.5" stroke="currentColor" class="size-6">
                      <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                    </svg>
                    <svg v-else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                      stroke="currentColor" class="size-6">
                      <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 15.75 7.5-7.5 7.5 7.5" />
                    </svg>
                  </span>
                </div>
                <div v-if="isShow1">
                  I live in United State
                </div>
              </div>
            </div>
            <div class=" flex justify-center">
              <div @click="isShow2 = !isShow2"
                class="cursor-pointer bg-white border w-full ml-3  p-3 rounded-md relative">
                <div class="font-bold text-lg flex justify-between w-full">
                  <div>What is your job ?</div>
                  <span>
                    <svg v-if="!isShow" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                      stroke-width="1.5" stroke="currentColor" class="size-6">
                      <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                    </svg>
                    <svg v-else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                      stroke="currentColor" class="size-6">
                      <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 15.75 7.5-7.5 7.5 7.5" />
                    </svg>
                  </span>
                </div>
                <div v-if="isShow2">
                  I have no job.
                </div>
              </div>
            </div>
          
          </div>
        </div>
      </div>
    </div>

    <FooterComponentVue />
  </div>
</template>

<script>

import BannerComponentVue from '@/components/BannerComponent.vue'
import { ref } from 'vue';
import FooterComponentVue from '@/components/FooterComponent.vue'

export default {
  name: 'HomeView',
  components: {

    BannerComponentVue,
    FooterComponentVue
  },
  setup() {
    const image_url = ref([
      {
        id: 1,
        title: "Product 1",
        description: "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before the final copy is available.",
        url: "https://firebasestorage.googleapis.com/v0/b/master-it-system.appspot.com/o/product%2F2d4cc9ef574d81681fd2fc00d4f5869e.png?alt=media&token=e696fa03-95b8-4641-9298-3ab65946b8d9"
      },
      {
        id: 2,
        title: "HR System",
        description: "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before the final copy is available.",
        url: "https://firebasestorage.googleapis.com/v0/b/master-it-system.appspot.com/o/product%2Foriginal-31df326f2200cc107b8afba824d6d630.jpg?alt=media&token=6633eaf9-e819-45e7-9be5-86285b2dbbb6"
      },
    ])

    const logo = ref([
      {
        url: "https://firebasestorage.googleapis.com/v0/b/master-it-system.appspot.com/o/categories%2Fmaster-it%202.png?alt=media&token=b6b73a5c-3787-4945-825d-c65b74bccb71"
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/master-it-system.appspot.com/o/categories%2Ftelegram.png?alt=media&token=bfaff0b0-2dec-4229-8e37-0df0b83bd97a"
      },
      {
        url: 'https://firebasestorage.googleapis.com/v0/b/master-it-system.appspot.com/o/categories%2Fcompany_logo.jpg?alt=media&token=0a03e12f-f721-43ed-b0ed-b804a288b214'
      },
      {
        url: 'https://firebasestorage.googleapis.com/v0/b/master-it-system.appspot.com/o/categories%2FMasterIT.png?alt=media&token=1aa09421-6a92-460a-80f8-f47557c1f595'
      }
    ])

    const isShow = ref(false)
    const isShow1 = ref(false)
    const isShow2 = ref(false)

    return { image_url, logo, isShow,isShow1,isShow2 }
  }
}
</script>

<style>
.scroll-container {
  max-width: 100%;
  /* Ensure the container does not exceed the viewport width */
}

.scroll-item {
  min-width: 150px;
  /* Set a minimum width for items to demonstrate scrolling */
}
</style>
