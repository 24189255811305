<template>
  <div>
    <div class="my-10">
      <h1 class="text-center text-3xl my-3 font-bold text-primary">ផលិតផល និងសេវាកម្មយើង</h1>
      <p class="text-center text-lg">ម៉ាស្ទ័រអាយធី ស៊ីស្ទីម ផ្ដល់ដំណោះស្រាយផ្នែកបច្ចេកវិទ្យា
        តាមរយៈការផ្គត់ផ្គង់ប្រព័ន្ធគ្រប់គ្រងសម្រាប់អាជីវកម្ម គ្រប់ប្រភេទរៀបចំ បង្កើតគេហទំព័រ
        និងប្រព័ន្ធគ្រប់គ្រងទិន្នន័យថ្មី តាមតម្រូវការ។</p>
    </div>

    <div class="w-9/12 mx-auto mb-10">
      <div class="grid grid-cols-1 md:grid-cols-3 gap-10 ">
        <div v-for="con in content" :key="con"
        >
          <div class="shadow-xl rounded-lg h-full">
            <img  :src="con.url" style="height: 400px;width:100%" class="object-center"  alt="">
            <div class="-mt-[6rem] overflow-hidden ">
              <svg data-v-4d6735fc="" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                <path data-v-4d6735fc="" fill="#ffffff" fill-opacity="1"
                  d="M0,128L60,149.3C120,171,240,213,360,197.3C480,181,600,107,720,64C840,21,960,11,1080,48C1200,85,1320,171,1380,213.3L1440,256L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z">
                </path>
              </svg>

              <div class="p-3 my-2">
                <h1 class="text-2xl text-primary font-bold mb-5">
                  {{ con.title }}
                </h1>
                <p class="line-clamp-2 text-lg text-gray-500">{{ con.description }}</p>
              </div>

              <div class="m-5">
                  <button class="bg-white p-2 px-6 hover:bg-primary transition-all duration-150 ease-in-out hover:bottom-2 hover:text-white text-lg border-2 rounded-md text-gray-500">View</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

  <FooterComponentVue/>
  
</template>

<script>
import { ref } from 'vue'
import FooterComponentVue from "@/components/FooterComponent.vue"
export default {
  components: {
    FooterComponentVue
  },
  setup() {
    const content = ref([
      {
        title: "Website Design",
        url: "https://firebasestorage.googleapis.com/v0/b/master-it-system.appspot.com/o/categories%2F2.jpg?alt=media&token=9cb59c69-f506-4b1b-9967-fd7f3938538b",
        description: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like)."
      },
      {
        title: "Business Management System",
        url: "https://firebasestorage.googleapis.com/v0/b/master-it-system.appspot.com/o/categories%2F7.jpg?alt=media&token=2abb536c-c4a7-495a-8955-f62740644991",
        description: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like)."
      },
      {
        title: "System Development",
        url: "https://firebasestorage.googleapis.com/v0/b/master-it-system.appspot.com/o/categories%2F3.png?alt=media&token=af0cd1bc-8e20-45d7-bd12-3081f965177d",
        description: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like)."
      }
    ])

    return { content }
  }
}
</script>

<style></style>